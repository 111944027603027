import { css } from 'styled-components';

export const theme = {
  global: {
    colors: {
      brand: '#e62e03',
      'accent-1': '#ff9900'
    },
    font: {
      family: 'Montserrat'
    },
    breakpoints: {
      hero: {
        value: 1126
      }
    }
  },
  button: {
    padding: {
      vertical: '8px'
    },
    border: {
      radius: '28px'
    }
  },
  anchor: {
    extend: () => css`box-shadow: none`
  },
  heading: {
    level: {
      1: {
        font: {
          weight: '400'
        },
        small: {
          maxWidth: "100%"
        }
      },
    }
  }
};

export default theme;
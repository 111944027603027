import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import { StaticQuery, graphql, Link } from "gatsby"
import Image from 'gatsby-image';

import InstallButton from './installButton';

const Header = ({ logo }) => {
  return (
    <Box
      border={{
        color: 'rgba(09, 0, 0, 0.08)',
        side: 'bottom'
      }}
      align='center'
      pad='medium'
    >
      <Box
        width='1000px'
        justify='between' 
        align='center'
        direction='row'
      >

        <Link to='/'>
          <Box
            direction='row'
            gap='small'
          >
            <Text size='xlarge' weight='bold'>
              <span style={{ color: '#ff6000' }}>1</span>
              <span style={{ color: '#ff2900' }}>TT</span>
              {' '}<span style={{ color: '#444444' }}>Downloader</span>
            </Text>
            <Image
              fixed={logo}
              alt='1TT logo icon'
            />
          </Box>
        </Link>
        <ResponsiveContext.Consumer>
          {size => (
            <Box direction='row' gap={size === 'small' ? 'small' : 'medium'} align='center'>
              {/* <Link to='/blog' activeStyle={{ textDecoration: 'underline' }}>
                <Anchor
                  as='span'
                  label={<Text size={size === 'small' ? 'small' : 'medium'}>Blog</Text>}
                  color='dark-3'
                />
              </Link>
              <Link to='/updates' activeStyle={{ textDecoration: 'underline' }}>
              <Anchor
                as='span'
                label={<Text size={size === 'small' ? 'small' : 'medium'}>Updates</Text>}
                color='dark-3'
              />
              </Link> */}
              {size === 'small' ? null : <InstallButton />}
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Box>
    </Box>
  );
}

const HeaderContainer = () => {
  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        return <Header logo={data.logo.childImageSharp.fixed} />
      }}
    />
  );
}

const headerQuery = graphql`
  query HeaderQuery {
    logo: file(absolutePath: { regex: "/icon-96.png/" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default HeaderContainer;
import React from 'react';
import { Button, Text } from 'grommet';
import {
  Chrome as ChromeIcon,
  Firefox as FirefoxIcon
} from 'grommet-icons';

const InstallButton = props => {
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const chromeListingUrl = 'https://chrome.google.com/webstore/detail/hcgcimicgchmlleoghonjpfhckpjmlij';
  const firefoxListingUrl = 'https://addons.mozilla.org/en-US/firefox/addon/aliexpress-image-downloader/';
  return (
    <Button
      label={<Text color='white' weight='bold'>Install</Text>}
      icon={isFirefox === true ? <FirefoxIcon/> : <ChromeIcon/>}
      primary={true}
      style={{ textAlign: 'center' }}
      href={isFirefox === true ? firefoxListingUrl : chromeListingUrl}
      {...props}
    />
  );
}

export default InstallButton;
import React from "react"
import { Grommet, Box } from 'grommet';

import { theme } from '../utils/theme'
import Header from './header';

class BlogArchiveLayout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Grommet theme={theme}>
        <Header/>
        <Box align='center'>
          <Box tag='main' width='750px' pad='medium'>{children}</Box>
        </Box>
      </Grommet>
    );
  }
}

export default BlogArchiveLayout
